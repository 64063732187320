import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListConsultantCommunity = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);
  data.planningName && params.append("planningName", data.planningName);
  data.planningType && params.append("planningType", data.planningType);
  data.approvalLevel && params.append("approvalLevel", data.approvalLevel);
  data.place && params.append("place", data.place);
  data.planningAgency && params.append("planningAgency", data.planningAgency);
  data.consultingUnit && params.append("consultingUnit", data.consultingUnit);
  data.startTime && params.append("startTime", data.startTime);
  data.endTime && params.append("endTime", data.endTime);

  return service
    .get(ApiUrl.GetListConsultantCommunity, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetListResultConsultantCommunity = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);
  data.planningName && params.append("planningName", data.planningName);
  data.planningType && params.append("planningType", data.planningType);
  data.approvalLevel && params.append("approvalLevel", data.approvalLevel);
  data.place && params.append("place", data.place);
  data.planningAgency && params.append("planningAgency", data.planningAgency);
  data.consultingUnit && params.append("consultingUnit", data.consultingUnit);
  data.startTime && params.append("startTime", data.startTime);
  data.endTime && params.append("endTime", data.endTime);

  return service
    .get(ApiUrl.GetListResultConsultantCommunity, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailConsultantCommunity = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);

  return service
    .get(ApiUrl.GetDetailConsultantCommunity, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailFormTemplate = (id) => {
  const params = new URLSearchParams();
  params.append("consultantCommunityId", id);

  return service
    .get(ApiUrl.GetDetailFormTemplate, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreateFeedback = (data) => {
  return service
    .post(ApiUrl.CreateFeedback, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UploadFileFeedback = (data) => {
  return service
    .post(ApiUrl.UploadFileFeedback, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetListExpiredForm = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);
  data.sortExpression && params.append("sortExpression", data.sortExpression);
  data.title && params.append("title", data.title);
  data.consultantCommunityId &&
    params.append("consultantCommunityId", data.consultantCommunityId);

  return service
    .get(ApiUrl.GetListExpiredForm, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetReportFeedback = (id) => {
  const params = new URLSearchParams();
  params.append("templateId", id);

  return service
    .get(ApiUrl.GetReportFeedback, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
