import React, { useState, useEffect } from "react";
import PlanningMapView from "../planning-map-view/planning-map-view.view"

function ProjectManagement(props) {
    const planningId = 150;
    const [data, setData] = useState();

    // const handleData = (strData) => {
    //     let arr = strData.split('.');
    //     setTableName(arr[0]);
    //     setGid(parseInt(arr[1]));
    // }
    return (
        <div>
            <PlanningMapView
                isProject={true}
                planningId={planningId}
                setDataProject={setData}
                dataProject={data}
            />
        </div>
    )
}

export default ProjectManagement