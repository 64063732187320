import React, { useEffect, useState } from "react";
import * as statementAction from "../../../redux/store/statement/statement.store";
import { Link } from "react-router-dom";
import UrlCollect from "../../../common/url-collect";
import * as configuration from "../../../utils/configuration";

export default function ServiceLink() {
    const [serviceModels, setServiceModels] = useState(null);

    useEffect(() => {
        statementAction
            .GetSerivceLink()
            .then(
                (res) =>
                    res &&
                    res.content &&
                    res.content.linkGroup &&
                    setServiceModels(res.content.linkGroup)
            )
            .catch((err) => { });
    }, []);

    return (
        <div>
            <div className="header-result">
                <div className="title">
                    <Link className="text-uppercase" to={UrlCollect.SyntheticPlanning}>
                        BẢN ĐỒ TỔNG HỢP QUY HOẠCH
                    </Link>
                </div>
            </div>
            {serviceModels &&
                serviceModels.map((items) =>
                    items.name === "MAP" && (
                        <div className="item">
                            <div className="row">
                                <div className="col-12">
                                    {items.linkService &&
                                        items.linkService.length > 0 &&
                                        items.linkService.map((linkService, index1) => (
                                            <img
                                                src={
                                                    configuration.APIUrlDefault + linkService.image
                                                }
                                                alt="Map"
                                                className="img-fluid w-100 mb-2 mb-md-0"
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    )
                )}
        </div>
    );
}
