import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListAll = (pageIndex, pageSize, sorting, name, place, typeId) => {
    const params = new URLSearchParams();
    pageIndex && params.append("pageIndex", pageIndex);
    pageSize && params.append("pageSize", pageSize);
    sorting && params.append("sorting", sorting);
    name && params.append("name", name);
    place && params.append("place", place);
    typeId && params.append("typeId", typeId);
    return service
        .get(ApiUrl.GetListAllProject, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetDetail = (gid, tableName) => {
    const params = new URLSearchParams();
    gid && params.append("gid", gid);
    tableName && params.append("tableName", tableName);
    return service
        .get(ApiUrl.GetDetailProject, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetLookupStatus = () => {
    return service
        .get(ApiUrl.GetLookupProjectStatus)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
}

export const GetLookupType = () => {
    return service
        .get(ApiUrl.GetLookupProjectType)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
}

export const GetLookupInvestor = () => {
    return service
        .get(ApiUrl.GetLookupInvestor)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
}
