import React, { useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import UploadFileIcon from '@material-ui/icons/Backup';

// import classNames from "classnames";
import "./drozone.scss"

export const Uploader = ({ accept, filesLimit, maxFileSize, setFile, textDisplay }) => {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        accept: accept,
        maxSize: maxFileSize,
        maxFiles: filesLimit
    });

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {Math.round(file.size / 1000)} kB
        </li>
    ));

    useEffect(() => {
        setFile(acceptedFiles);
    },[acceptedFiles])

    // const className = classNames("wfp--dropzone__input", {
    //     "wfp--dropzone__input--drag-active": isDragActive,
    //     [className]: className
    // });

    return (
        <section className="wfp--dropzone">
            <div {...getRootProps({ isDragActive, className: 'dropzone' })}>
                <input {...getInputProps()} />
                {/* <Icon className="wfp--dropzone__icon" icon={iconUpload} /> */}
                <div className="textDisplay">
                    <p>{textDisplay}</p>
                    <UploadFileIcon className="text-info" size="large" />
                </div>
                <aside className="wfp--dropzone__file-list">
                    <ul>{files}</ul>
                </aside>

            </div>
        </section>
    );
};
