/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as apiConfig from '../../../api/api-config';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { geolocated } from "react-geolocated";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faMap as baseMapIcon,
  faInfoCircle,
  faSlidersH,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import * as StatementAction from "../../../redux/store/statement/statement.store";
import {
  SetDisplayMaptoolPanel,
  SetDisplayInfomationPopup,
} from "../../../redux/store/open-layer/map-tools-panels.store";
import HandleErrorOnGetGeoloaction from '../../../common/handle-error-navigator-geolocation';
import {
  TopRightPanelPropsModel,
  CoordsModel,
  PlanningModelMapTool,
} from "../../../models/planning-map-view/top-right-panel-models";
import SelectCurrentLayerView from "../select-current-layer/select-current-layer.view";
import { ExtracAllLayersArrays } from "../../../common/extract-information-from-map-data";
import ViLanguages from "../../../languages/vi";
import { Tooltip } from '@material-ui/core';
import "./top-right-panel.scss";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import { GetLatLngPlace, GetVn2000ToWgs84 } from "../../../redux/store/home-page/home-page.store";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../../utils/configuration";
import * as mapHelper from "../../../common/map";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import * as map from "../../../common/map";
import { isMobile } from 'react-device-detect';
const LanguageCollects = ViLanguages;

function TopRightPanelView(props: TopRightPanelPropsModel) {
  let commentInput: any = null;

  const [address, setAddress] = useState("");
  const [currentPosition, setCurrentPosition] = useState<CoordsModel>();
  const [planningModel, setPlanningModel] = useState<PlanningModelMapTool>();
  const [currentPolygon, setCurrentPolygon] = useState<number[][][]>();

  useEffect(() => {
    onGetData();
    //props.GetAllPlanningBoundariesGeomText && props.GetAllPlanningBoundariesGeomText();
  }, []);
  useEffect(() => {
    getPolygonById();
  }, [props.planningId, props.listBoundaries])

  const onGetData = () => {
    document
      .getElementById("dropdownSelectBaseMap")
      ?.addEventListener("click", () => {
        props.SetDisplayInfomationPopup(false);
      });

    navigator.geolocation.getCurrentPosition((position: any) => {
      setCurrentPosition(position.coords);
    });

    props.planningId &&
      props.planningId > 0 &&
      onGetDetailPlanningById(props.planningId);
  };

  const onGetDetailPlanningById = (planningId: number) => {
    StatementAction.GetDetailPlanningById(planningId).then((res) => {
      const result = (res && res.content) || null;
      setPlanningModel(result);
      props.setPlanningModelInMap && props.setPlanningModelInMap(result)
    });
  };

  //--- Change base map
  const handleOnClickChangeBaseMap = (index: number) => {
    setOpenBaseMap(false);
    props.openLayerController.changeBaseMap(props.baseMapList[index].url);
  };

  //--- Open map tools
  const toggleDisplayMapToolsPanel = () => {
    props.SetDisplayMaptoolPanel(!props.isMapToolsPanelsDisplay);
    setOpenCurrentLayerView(false);
    setOpenBaseMap(false);
    setOpenInfo(false);
    setOpenSearch(false);
  };

  //--- Open current layer view
  const [openCurrentLayerView, setOpenCurrentLayerView] = useState(false);
  const handleClickCurrentLayerView = () => {
    setOpenCurrentLayerView(!openCurrentLayerView);
    setOpenBaseMap(false);
    props.SetDisplayMaptoolPanel(false);
    setOpenInfo(false);
    setOpenSearch(false);
  };

  //--- Open base map
  const [openBaseMap, setOpenBaseMap] = useState(false);
  const handleClickBaseMap = () => {
    setOpenBaseMap(!openBaseMap);
    setOpenCurrentLayerView(false);
    props.SetDisplayMaptoolPanel(false);
    setOpenInfo(false);
    setOpenSearch(false);
  };

  //--- Open info
  const [openInfo, setOpenInfo] = useState(false);
  const handleClickInfo = () => {
    setOpenInfo(!openInfo);
    setOpenBaseMap(false);
    setOpenCurrentLayerView(false);
    props.SetDisplayMaptoolPanel(false);
    setOpenSearch(false);
  };

  //--- Open search
  const [openSearch, setOpenSearch] = useState(false);
  const handleClickSearch = () => {
    setOpenSearch(!openSearch);
    setOpenInfo(false);
    setOpenBaseMap(false);
    setOpenCurrentLayerView(false);
    props.SetDisplayMaptoolPanel(false);
  };

  //--- Open locate
  const [openLocate, setOpenLocate] = useState(true);
  const handleClickLocate = () => {
    setOpenLocate(true);
    setOpenCoordinates(false);
  };

  //--- Open coordinates
  const [openCoordinates, setOpenCoordinates] = useState(false);
  const handleClickCoordinates = () => {
    setOpenCoordinates(true);
    setOpenLocate(false);
  };
  const searchCoordinate = (event: any) => {
    event.preventDefault();
    if (openCoordinates) {
      console.log(commentInput.value.trim())
      let coordinate = commentInput.value.trim().split(',');
      let x = coordinate[0];
      let y = coordinate[1];
      GetVn2000ToWgs84(x, y).then(res => {
        if (res) {
          history.push(
            `${UrlCollect.PlanningMapViewDetail
            + props.planningId}?lat=${res[1]}&lng=${res[0]}&searchType=place`);
        }
      })
      window.location.reload()
      // props.openLayerController.handleSearchCoordinate(
      //   commentInput && commentInput.value ? commentInput.value.trim() : ""
      // );
    }
  };

  const [filteredSuggestions, setFilteredSuggestions] = useState([])

  const Options = async (search: string, loadedOptions: any) => {
    if (search.length >= 4) {
      GetLatLngPlace(search)
        .then((res) => {
          setFilteredSuggestions(res && res.result && res.result.length > 0 ? res.result : [])
        })
    }
    const addressSuggestions = filteredSuggestions.map((item: any, index) => {
      return ({
        value: index,
        label: item.address
      })

    })
    return {
      options: addressSuggestions,

    }
  }

  const handleSelectAddress = (address: any) => {
    //setAddress(address);
    GetLatLngPlace(address.label)
      .then((res) => res.result[0])
      .then((result) => result.location)
      .then((latLng) => {
        console.log(latLng)
        history.push(`${UrlCollect.PlanningMapViewDetail + props.planningId}?lat=${latLng.lat}&lng=${latLng.lng}&searchType=place`)
        window.location.reload()
        // if (checkAddressInPolyon(latLng.lng, latLng.lat)) {
        //   props.openLayerController.placeSearch([latLng.lng, latLng.lat]);
        //   props.openLayerController.functionClickViewInfomationListener({ coordinate: [latLng.lng, latLng.lat] })
        //   history.push(`${UrlCollect.PlanningMapViewDetail + props.planningId}?lat=${latLng.lat}&lng=${latLng.lng}&searchType=place`)
        // } else {
        //   ShowNotification(
        //     viVN.Errors.CoordinateOutSidePolygon,
        //     NotificationMessageType.Warning,
        //     NotificationPosition.Center
        //   );
        // }
      })
      .catch((error) => console.error("Error", error));
  };

  const handleClickCurrentLocation = () => {
    if (!currentPosition) {
      navigator.geolocation.getCurrentPosition((position) => {
        position &&
          position.coords && checkAddressInPolyon(position.coords.longitude, position.coords.latitude) &&
          props.openLayerController.placeSearch([
            position.coords.longitude,
            position.coords.latitude,
          ]);
      }, HandleErrorOnGetGeoloaction);
    } else {

      checkAddressInPolyon(currentPosition.longitude, currentPosition.latitude) && props.openLayerController.viewCurrentLocaltion()
    }
  };

  const classnames = (...args: any) => {
    const classes: any = [];
    args.forEach((arg: any) => {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (typeof arg === "object" && arg !== null) {
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            classes.push(key);
          }
        });
      } else {
        throw new Error(
          "`classnames` only accepts string or object as arguments"
        );
      }
    });

    return classes.join(" ");
  };

  function checkAddressInPolyon(x: any, y: any) {
    let isInside = false;
    currentPolygon?.map((item: any) => {
      if (mapHelper.isPointInPolygon(y, x, item)) {
        isInside = true;
        return;
      }
    });
    if (isInside) {
      return true;
    } else {
      ShowNotification(
        viVN.Errors.CoordinateOutSidePolygon,
        NotificationMessageType.Warning,
        NotificationPosition.Center
      );
    }
    return false;
  }
  function getPolygonById() {
    let arrCoordinatePolygon: number[][][] = [];
    let polygonCurrent = props.listBoundaries && props.listBoundaries.length > 0 && props.listBoundaries.find((item) => item.id == props.planningId);
    polygonCurrent && arrCoordinatePolygon.push(map.convertStringToArray(polygonCurrent.geomText));
    setCurrentPolygon(arrCoordinatePolygon);
  }

  return (
    <div id="map-tool-panel-right" className="position-absolute tools-box-right-container dropdown dropleft pt-1 pb-1">
      <div className="position-relative">
        {!props.isProject && (
          <Tooltip title="Tìm kiếm địa điểm">
            <button
              id="map-tool-panel-right-search-box"
              hidden={props.isHiddenSearch}
              className="btn"
              onClick={handleClickSearch}
            >
              <FontAwesomeIcon icon={faSearch} color="#00786E" />
            </button>
          </Tooltip>
        )}


        {openSearch && (
          <div id="map-tool-panel-right-search-result" className="dropdown-search position-absolute">
            <form
              onSubmit={searchCoordinate}
              className="bg-white pl-2 pr-2 pb-1 pt-1 d-flex align-items-center justify-content-around rounded shadow-sm"
            >
              <img
                id="map-tool-panel-right-search-result-place"
                src={require("../../../assets/icon/search-circle.svg")}
                onClick={searchCoordinate}
                alt="Search"
              />
              {openLocate && (
                <AsyncPaginate
                  id="search-adress"
                  className="react-select-container mr-3 ml-3 select-search"
                  //classNamePrefix="react-select"
                  cacheOptions
                  defaultMenuIsOpen={true}
                  defaultOptions
                  loadOptions={Options}
                  onChange={handleSelectAddress}
                  placeholder="Nhập địa điểm tra cứu"
                  noOptionsMessage={() => "Không có dữ liệu"}
                />
              )}
              {openCoordinates && (
                <input
                  type="text"
                  ref={(input: any) => {
                    commentInput = input;
                  }}
                  placeholder="Nhập tọa độ VN2000 (Tọa độ X, Tọa độ Y)"
                  className="ml-2"
                />
              )}
            </form>
            <div id="map-tool-panel-right-search-result-action" className="mt-2">
              <img
                src={require("../../../assets/icon/planning-name.svg")}
                alt="Planning"
                onClick={handleClickCoordinates}
              />
              <img
                src={require("../../../assets/icon/place.svg")}
                alt="Place"
                className="ml-2"
                onClick={handleClickLocate}
              />
              <img
                src={require("../../../assets/icon/locate.svg")}
                alt="Locate"
                className="ml-2"
                onClick={handleClickCurrentLocation}
              />
            </div>
          </div>
        )}
      </div>

      <div className="position-relative">
        <Tooltip title="Lớp cơ sở">
          <button
            hidden={props.isHiddenLayer}
            className="btn"
            onClick={handleClickCurrentLayerView}
          >
            <FontAwesomeIcon icon={faSlidersH} color="#00786E" />
          </button>
        </Tooltip>


        {openCurrentLayerView && props.mapData && (
          <div>
            <div className="position-relative">
              <div className="position-absolute close-current-layer-view">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleClickCurrentLayerView}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <SelectCurrentLayerView
              listLayers={ExtracAllLayersArrays(props.mapData)}
            />
          </div>
        )}
      </div>
      {!props.isProject && (
        <div className="position-relative">
          <Tooltip title="Thông tin chung của quy hoạch">
            <button
              hidden={props.isHiddenInfor}
              className="btn"
              type="button"
              onClick={handleClickInfo}
              id='planning-infomation-small-popup-button'
            >
              <FontAwesomeIcon icon={faInfoCircle} color="#00786E" />
            </button>
          </Tooltip>
          {openInfo && (
            <div className="dropdown-info mt-1 border-0 p-2 bg-white rounded position-absolute shadow-sm" id="planning-infomation-small-popup">
              <h6>Quy hoạch tổng thể: {planningModel?.name}</h6>
              <h6>
                Diện tích: {planningModel?.acreage} km<sup>2</sup>
              </h6>
              <h6>Dân số: {planningModel?.population} người</h6>
              <a href={planningModel?.documenOfStatement ? (apiConfig.api + planningModel?.documenOfStatement) : '#'}
                target={planningModel?.documenOfStatement ? '_blank' : ""}
              >
                <h6 className="mb-0">
                  Quyết định phê duyệt: {planningModel?.numberOfDecisions}
                </h6>
              </a>
            </div>
          )}
        </div>
      )}


      <div className="position-relative">
        <Tooltip title={LanguageCollects.planningMapView.baseMap}>
          <button className="btn" type="button" onClick={handleClickBaseMap}>
            <FontAwesomeIcon
              icon={baseMapIcon}
              color="#00786E"
            />
          </button>
        </Tooltip>

        {openBaseMap && props.baseMapList.length > 0 && (
          <div className="dropdown-base-map mt-1 border-0 pt-1 pb-1 bg-white rounded position-absolute shadow-sm">
            {props.baseMapList.map((basemap, index) => (
              <div
                key={index}
                onClick={() => handleOnClickChangeBaseMap(index)}
                className="dropdown-item"
              >
                {basemap.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <Tooltip title={LanguageCollects.planningMapView.toolsMap}>
        <button
          hidden={props.isHiddenMapTools}
          className="btn"
          id="map-tools-control-display"
          onClick={() => toggleDisplayMapToolsPanel()}
        >
          <FontAwesomeIcon
            icon={faCogs}
            color="#00786E"
          />
        </button>
      </Tooltip>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  isMapToolsPanelsDisplay: state.mapToolsPanel.displayPanel,
  mapData: state.mapData.mapData,
  openLayerController: state.openlayer.openLayerController,
  listBoundaries: state.mapData.listBoundaries,
  listPolygon: state.mapData.listPolygon,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      SetDisplayMaptoolPanel: SetDisplayMaptoolPanel,
      SetDisplayInfomationPopup: SetDisplayInfomationPopup,
      GetAllPlanningBoundariesGeomText: MapDataStore.GetAllPlanningBoundariesGeomText,
    },
    dispatch
  );

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(TopRightPanelView));
