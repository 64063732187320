import React from 'react';

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <>
        <img
            src={require("../../assets/icon/arrow-right_.png")}
            alt="right"
            {...props}
            className={
                'slick-next slick-arrow' + (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
        />
    </>
);

export default SlickArrowRight;
