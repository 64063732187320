import React from "react";
import './lookupPlanning.scss'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import { LookupPlanningType } from "./lookupPlanningType.view"

function LookupPlanningHome(props) {
    const { showLoading } = props;

    return (
        <div>
            <section className="planning-type">
                <LookupPlanningType
                    showLoading={showLoading}
                    planningTypeId={2}
                    planningName='Quy hoạch chung'
                />
            </section>
            <section className="planning-type bg-white">
                <LookupPlanningType
                    showLoading={showLoading}
                    planningTypeId={3}
                    planningName='Quy hoạch chi tiết'
                />
            </section>
            <section className="planning-type">
                <LookupPlanningType
                    showLoading={showLoading}
                    planningTypeId={1}
                    planningName='Quy hoạch Phân khu'
                />
            </section>
            {/* <section className="planning-type1">
                <LookupPlanningType
                    showLoading={showLoading}
                    planningTypeId={4}
                    planningName='Quy hoạch khu chức năng đặc thù'
                />
            </section> */}
        </div>
    )
}
const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    listPolygon: state.mapData.listPolygon
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );


export default (connect(mapStateToProps, mapDispatchToProps)(LookupPlanningHome));;