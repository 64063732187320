const UrlCollect = {
  Home: '/',
  SpecializedPlanning: '/quy-hoach-chuyen-nganh',
  SyntheticPlanning: '/tong-hop-quy-hoach',
  GeneralPlanning: '/quy-hoach-chung',
  SubdivisionPlanning: '/quy-hoach-phan-khu',
  DetailedPlanning: '/quy-hoach-chi-tiet',
  PlanningAnnouncement: '/cong-bo-quy-hoach',
  News: '/news',
  RecordsManager: '/quan-ly-ho-so',
  Contact: '/lien-he',
  PlanningMapViewDetail: '/ban-do-quy-hoach/',
  PlanningMap: '/ban-do-quy-hoach',

  // -- Phan anh
  Reflect: '/phan-anh-hien-truong',
  ReflectByCategory: '/phan-anh-hien-truong/chuyen-muc/:categoryInfo',
  ReflectDetail: '/phan-anh-hien-truong/phan-anh/:reflectInfo',
  MyReflect: '/phan-anh-cua-ban',
  CreateReflect: '/phan-anh-kien-nghi',
  ReflectMap: '/ban-do-phan-anh-hien-truong',

  //-- User
  ConfirmCode: '/xac-nhan',
  ResetPassword: '/dat-lai-mat-khau',
  UserInfo: '/thong-tin-ca-nhan',

  //-- Xin y kien cong dong
  PleaseConsultTheCommunity: '/y-kien-cong-dong',
  PleaseComment: '/xin-y-kien',
  SummaryIdeas: '/y-kien-tong-hop',

  //-- Tin tuc
  NewsList: '/tin-tuc',
  NewsDetail: '/tin-tuc-chi-tiet',
  NewsListQHT: '/tin-tuc-quy-hoach-tinh',
  NewsListQHHTKT: '/tin-tuc-noi-dung-qhhtkt',
  NewsListQHCC: '/tin-tuc-quy-hoach-cac-cap',
  NewsDetailQHT: '/tin-tuc-quy-hoach-tinh/chi-tiet',
  NewsDetailQHHTKT: '/tin-tuc-noi-dung-qhhtkt/chi-tiet',
  NewsDetailQHCC: '/tin-tuc-quy-hoach-cac-cap/chi-tiet',

  //-- Error
  Page500: '/page-500/',

  //-- Admin
  ProcessBoardPlanning: '/quy-trinh-cong-bo-quy-hoach',
  
  //-- Dự án đầu tư xây dựng
  ProjectManagement: '/du-an-dau-tu-xay-dung',

  //-- Hồ sơ cấp phép
  LisenceFile: '/ho-so-cap-phep',
};

export default UrlCollect;
