import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import * as projectAction from "../../../redux/store/project/project.store";
import * as ApiConfig from "../../../api/api-config";
import { ApiUrl } from "../../../api/api-url.js";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../languages/vi-VN.json";
import ClickOutside from "components/click-outside/click-outside";
//--Mui
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faUsers, faCoins, faHotel, faChartArea, faStream } from "@fortawesome/free-solid-svg-icons";
import Pagination from '@material-ui/lab/Pagination';
import {
    Button,
    IconButton,
    Tooltip,
    Paper,
    InputBase,
    Divider,
} from "@material-ui/core";
import { Menu, Refresh } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import MapIcon from "@material-ui/icons/Map";
import Select from "react-select";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./left-project.scss"

function ListProject(props) {
    const { showLoading, dataProject, setProjectcoordinate } = props;
    const [gid, setGid] = useState();
    const [tableName, setTableName] = useState();
    const [listProject, setListProject] = useState();
    const [projectModel, setProjectModel] = useState();
    const [documentModel, setDocumentModel] = useState();
    const [galleryModel, setGalleryModel] = useState();
    const [planningId, setPlanningId] = useState();
    const [investorModel, setInvestorModel] = useState([]);
    const [investor, setInvestor] = useState("");
    const [statusModel, setStatusModel] = useState();
    const [status, setStatus] = useState("");
    const [typeModel, setTypeModel] = useState();
    const [type, setType] = useState("");
    const [input, setInput] = useState("");
    const [coordinate, setCoordinate] = useState();
    const [isOpenCIDetail, setIsOpenCIDetail] = useState(false);
    const [isOpenSearch, setIsOpenSearch] = useState(false);
    const [isOpenSearchEx, setIsOpenSearchEx] = useState(false);
    const [options, setOptions] = useState('');
    const [page, setPage] = useState();
    const [totalPage, setTotalPage] = useState();
    const [totalItemCount, setTotalItemCount] = useState();
    const [sort, setSort] = useState('modifiedDate desc');
    const [place, setPlace] = useState('');
    const [typeId, setTypeId] = useState(0);
    const pageSize = 10;

    const Options = [
        { value: 'modifiedDate desc', label: 'Mới nhất' },
        { value: 'modifiedDate asc', label: 'Cũ nhất' },
    ]

    const settingSlider = {
        //dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    useEffect(() => {
        if (dataProject) {
            handleData(dataProject)
            console.log('dataProject', dataProject)
        }
    }, [dataProject])

    useEffect(() => {
        if (gid && tableName)
            GetProjectDetail(gid, tableName)
    }, [gid, tableName])

    useEffect(() => {
        GetListAll(1, pageSize, sort, input);
        GetLookupInvestor();
        GetLookupStatus();
        GetLookupType();
    }, [])

    useEffect(() => {
        if (projectModel?.typeId && typeModel) {
            setType(typeModel.find((x) => x.id === projectModel.typeId))
        }
    }, [projectModel, typeModel])
    useEffect(() => {
        if (projectModel?.constructionInvestmentStatusId && statusModel) {
            setStatus(statusModel.find((x) => x.id === projectModel.constructionInvestmentStatusId))
        }
    }, [projectModel, statusModel])
    useEffect(() => {
        if (projectModel?.investorId && investorModel) {
            setInvestor(investorModel.find((x) => x.id === projectModel.investorId))
        }
    }, [projectModel, investorModel])

    const handleData = (strData) => {
        let arr = strData.split('.');
        setTableName(arr[0]);
        setGid(parseInt(arr[1]));
    }

    const handleInputChange = (event) => {
        setInput(event.target.value)
    }

    const handlePlaceChange = (event) => {
        setPlace(event.target.value)
    }

    const keyPress = (event) => {
        if (event.keyCode == 13) {
            GetListAll(1, 10, sort, input, place, typeId);
            setIsOpenSearch(false)
        }
    }

    const refreshInput = () => {
        setInput('');
        setPlace('');
        setTypeId(0);
        GetListAll(1, 10, sort);
        setIsOpenSearch(false);
    }

    const convertCoordinate = (data) => {
        let arr = data.slice(11, data.indexOf(')')).split(' ')
        let coord = arr.map(i => parseFloat(i))
        let coorObject = { coordinate: coord }
        setProjectcoordinate(coorObject)
    }

    const handleChangePage = (_event, newPage) => {
        setPage(newPage - 1);
        GetListAll(newPage, pageSize, sort, input, place, typeId);
    };

    const handleSelectSort = (e) => {
        GetListAll(1, 10, e.value, input, place, typeId);
        setSort(e.value)
    }

    const handleSelectType = (data) => {
        console.log(data)
        setTypeId(data.id);
        GetListAll(1, 10, sort, input, place, data.id);
    }

    const GetLookupInvestor = () => {
        showLoading(true)
        projectAction.GetLookupInvestor().then((res) => {
            setInvestorModel(res && res.content ? res.content : [])
            showLoading(false)
        }).catch(err => showLoading(false))
    }

    const GetLookupStatus = () => {
        showLoading(true)
        projectAction.GetLookupStatus().then((res) => {
            setStatusModel(res && res.content ? res.content : [])
            showLoading(false)
        }).catch(err => console.log(err))
    }

    const GetLookupType = () => {
        showLoading(true)
        projectAction.GetLookupType().then((res) => {
            if (res && res.content) {
                let data = res.content.map((item) => {
                    return { ...item, label: item.name, value: item.id };
                })
                setOptions([{ label: 'Tất cả', value: 0 }, ...data])
                setTypeModel(res.content)
            }

            showLoading(false)
        }).catch(err => console.log(err))
    }

    const GetListAll = (pageIndex = 1, pageSize, sort, keyword, place, typeId) => {
        setPage(pageIndex - 1);
        showLoading(true);
        projectAction
            .GetListAll(pageIndex, pageSize, sort, keyword, place, typeId)
            .then((res) => {
                if (res && res.content) {
                    console.log(res)
                    setListProject(res.content.items);
                    setTotalItemCount(res.content.totalItemCount);
                    setTotalPage(res.content.pageCount)
                }
                showLoading(false);
            })
            .catch((err) => {
                ShowNotification(
                    viVN.Errors.AccessDenied,
                    NotificationMessageType.Error
                )
                showLoading(false);
            });
    };

    const GetProjectDetail = (gid, tableName) => {
        showLoading(true);
        projectAction.GetDetail(gid, tableName).then(res => {
            if (res) {
                setProjectModel(res.content)
                setDocumentModel(res.content.documentFiles ? res.content.documentFiles : [])
                setGalleryModel(res.content.galleryFiles)
                setPlanningId(res.content.PlanningDetailId ? res.content.PlanningDetailId : 0)
            } else {
                setProjectModel(null);
            }
            setIsOpenCIDetail(true);
            showLoading(false)
        }).catch(err => showLoading(false))
    }

    return (
        <div className="left_project">
            <div className="mb-4 construction">
                <div className="construction_title p-3">
                    <h2>Quản lý dự án đầu tư xây dựng trên địa bàn</h2>
                </div>
                <div className="row tab_">
                    <div
                        onClick={() => setIsOpenCIDetail(false)}
                        className={`col-6 ${!isOpenCIDetail ? 'active' : ''}`}
                        style={{ cursor: "pointer" }}
                    >
                        <p>Danh sách dự án</p>
                    </div>
                    {gid && tableName && (
                        <div
                            onClick={() => setIsOpenCIDetail(true)}
                            className={`col-6 ${isOpenCIDetail ? 'active' : ''}`}
                            style={{ cursor: "pointer" }}
                        >
                            <p>Thông tin chi tiết</p>
                        </div>
                    )}

                </div>
                {!isOpenCIDetail ? (
                    <div className="container">
                        <div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="length">
                                        <p>Có <span>{totalItemCount || 0}</span> kết quả</p>
                                    </div>

                                </div>
                                <div className="col-4" style={{ position: 'relative' }}>
                                    <Tooltip title="Tìm kiếm">
                                        <IconButton
                                            type="button"
                                            sx={{ p: '10px' }}
                                            aria-label="search"
                                            onClick={() => setIsOpenSearch(!isOpenSearch)}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <ClickOutside show={isOpenSearch} onClickOutside={() => { setIsOpenSearch(false) }}>
                                        {() => (
                                            <div className="left-map-search">
                                                <Paper
                                                    component="form"
                                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                                                >
                                                    {/* <IconButton sx={{ p: '10px' }} style={{ marginRight: '10px' }} aria-label="menu">
                                                            <Menu />
                                                        </IconButton> */}
                                                    <InputBase
                                                        className="search_item"
                                                        sx={{ ml: 1, flex: 1 }}
                                                        style={{ margin: '10px 10px 0 20px' }}
                                                        onChange={handleInputChange}
                                                        value={input}
                                                        onKeyDown={keyPress}
                                                        placeholder="Nhập tên dự án xây dựng"
                                                        inputProps={{ 'aria-label': 'search google maps' }}
                                                    />
                                                    <IconButton
                                                        type="button"
                                                        sx={{ p: '10px' }}
                                                        style={{ float: 'right' }}
                                                        aria-label="search"
                                                        onClick={() => refreshInput()}
                                                    >
                                                        <Refresh />
                                                    </IconButton>
                                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                                    <InputBase
                                                        className="search_item"
                                                        sx={{ ml: 1, flex: 1 }}
                                                        style={{ marginLeft: '20px' }}
                                                        onChange={handlePlaceChange}
                                                        value={place}
                                                        onKeyDown={keyPress}
                                                        placeholder="Nhập địa chỉ dự án"
                                                        inputProps={{ 'aria-label': 'search google maps' }}
                                                    />
                                                    <Select
                                                        className="search_item"
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: '#fff',
                                                                marginLeft: '10px',
                                                            }),

                                                        }}
                                                        options={options}
                                                        value={
                                                            options.filter((item) => {
                                                                if (typeId === item.value)
                                                                    return item
                                                            })
                                                        }
                                                        placeholder='Chọn loại dự án'
                                                        onChange={handleSelectType}
                                                    />
                                                </Paper>
                                            </div>
                                        )}
                                    </ClickOutside>

                                </div>
                                <div className="col-4">
                                    <div className="sort__">
                                        <Select
                                            options={Options}
                                            placeholder='Sắp xếp'
                                            onChange={handleSelectSort}
                                        />
                                    </div>
                                </div>
                            </div>
                            {listProject && listProject.length > 0 ? (
                                <div className="list_item">
                                    {listProject.map((item, index) => (
                                        <div className="project_item">
                                            <img
                                                className="project_img"
                                                src={ApiConfig.api + item.avatar?.filePreview}

                                            />
                                            <div
                                                className="project_content"
                                                onClick={() => {
                                                    setIsOpenCIDetail(true);
                                                    convertCoordinate(item.randomPoint);
                                                    setGid(item.gid);
                                                    setTableName(item.tableName);
                                                    //setProjectName(item.name)
                                                }}
                                            >
                                                <div className="">
                                                    <p className="project_title">
                                                        {item.name}
                                                    </p>
                                                    <p className="project_place">
                                                        Địa chỉ: {item.place}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <p>Không có thông tin dự án nào</p>
                                    </div>
                                </>
                            )}

                            {listProject && listProject.length > 0 && totalItemCount && totalItemCount > 0 && (
                                <div className="pagination__">
                                    <Pagination
                                        showFirstButton
                                        showLastButton
                                        page={page + 1}
                                        count={totalPage}
                                        siblingCount={1}
                                        boundaryCount={1}
                                        color='primary'
                                        onChange={handleChangePage}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <>
                        {projectModel ? (
                            <>
                                <div className="container_ci">
                                    <div className="name_ci">
                                        <h3>{projectModel?.name}</h3>
                                    </div>
                                    <div className="">
                                        <div className="slide">
                                            {galleryModel && galleryModel.length > 0 && (
                                                <div>
                                                    <Slider {...settingSlider}>
                                                        {galleryModel.map(
                                                            (item, index) =>
                                                                <div key={index}>
                                                                    <img src={ApiConfig.api + item.filePreview} alt={item.fileName} />
                                                                </div>
                                                        )}
                                                    </Slider>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    {projectModel?.planningDetailId > 0 &&
                                        <a className="pl-3" href={`${ApiConfig.domainUserSide}/ban-do-quy-hoach/${projectModel.planningDetailId}`} target='_blank'>
                                            Xem bản đồ chi tiết
                                            <Tooltip title="Xem">
                                                <IconButton
                                                    aria-label="visiable"
                                                >
                                                    <MapIcon
                                                        color="primary"
                                                        fontSize="small"
                                                    />
                                                </IconButton>

                                            </Tooltip>
                                        </a>
                                    }
                                    <div className="">
                                        <div className="row content_ci">
                                            <div className="col-4 item_ci">
                                                <p><FontAwesomeIcon icon={faMapMarkedAlt} style={{ color: "#005eff", marginRight: '5px' }} />
                                                    Địa chỉ
                                                </p>
                                            </div>
                                            <div className="col-8 value_ci">
                                                <p>{projectModel?.place}</p>
                                            </div>
                                        </div>
                                        <div className="row content_ci">
                                            <div className="col-4 item_ci">
                                                <p><FontAwesomeIcon icon={faUsers} style={{ color: "#005eff", marginRight: '5px' }} />
                                                    Chủ đầu tư
                                                </p>
                                            </div>
                                            <div className="col-8 value_ci">
                                                <p>{investor?.name}</p>
                                            </div>
                                        </div>
                                        <div className="row content_ci">
                                            <div className="col-4 item_ci">
                                                <p><FontAwesomeIcon icon={faCoins} style={{ color: "#005eff", marginRight: '5px' }} />
                                                    Quỹ đầu tư
                                                </p>
                                            </div>
                                            <div className="col-8 value_ci">
                                                <p>{projectModel?.investment}</p>
                                            </div>
                                        </div>
                                        <div className="row content_ci">
                                            <div className="col-4 item_ci">
                                                <p><FontAwesomeIcon icon={faHotel} style={{ color: "#005eff", marginRight: '5px' }} />
                                                    Loại hình
                                                </p>
                                            </div>
                                            <div className="col-8 value_ci">
                                                <p>{type.name}</p>
                                            </div>
                                        </div>
                                        <div className="row content_ci">
                                            <div className="col-4 item_ci">
                                                <p><FontAwesomeIcon icon={faChartArea} style={{ color: "#005eff", marginRight: '5px' }} />
                                                    Quy mô
                                                </p>
                                            </div>
                                            <div className="col-8 value_ci">
                                                <p>{projectModel?.scale}</p>
                                            </div>
                                        </div>
                                        <div className="row content_ci">
                                            <div className="col-4 item_ci">
                                                <p><FontAwesomeIcon icon={faStream} style={{ color: "#005eff", marginRight: '5px' }} />
                                                    Trạng Thái
                                                </p>
                                            </div>
                                            <div className="col-8 value_ci">
                                                <p>{status?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="content_ci">
                                            <p style={{ textAlign: 'justify' }}>{projectModel?.note}</p>
                                        </div>
                                        <div className="content_ci">
                                            {documentModel && documentModel.length > 0 && (
                                                <>
                                                    <a
                                                        style={{ fontWeight: "bold" }}
                                                        href={ApiConfig.api + ApiUrl.DownloadFileConstructionInvestment.replace("{id}", projectModel?.id)}
                                                        target="_blank"
                                                    >
                                                        Tải file đính kèm tại đây
                                                    </a>
                                                    {documentModel.map((item, index) => (
                                                        <div key={index}>
                                                            <a
                                                                style={{ color: '#007bff' }}
                                                                href={ApiConfig.api + item?.filePreview}
                                                                target="_blank"
                                                                download={item?.fileName}
                                                            >
                                                                {item?.fileName}
                                                            </a>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="p-5"> Chưa có thông tin dự án</p>
                            </>
                        )}

                    </>
                )}

            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ListProject);