const env = process.env.NODE_ENV || 'development';

const apiEnvironment = {
  development: {
    api: 'https://thanhhoa-api.cgis.vn/',
    apiCustom: 'https://pahtsonla-api.cgis.asia',
    domainUserSide: 'http://localhost:3000',
    domainAdminSide: 'http://localhost:3000',
    domainName: '',
    doMainNameGeoServer: 'https://geo.cgis.asia/geoserver/wfs',
    doMainNameGeoServerWMS: 'https://geo.cgis.asia/geoserver/thanhhoa/wms/',
    datastore: 'thanhhoa',
  },
  production: {
    api: 'https://thanhhoa-api.cgis.vn/',
    apiCustom: 'https://pahtsonla-api.cgis.asia',
    domainUserSide: 'https://thanhhoa.cgis.vn/',
    domainAdminSide: 'https://admin.thanhhoa.cgis.vn',
    domainName: 'cgis.vn',
    doMainNameGeoServer: 'https://geo.cgis.asia/geoserver/wfs',
    doMainNameGeoServerWMS: 'https://geo.cgis.asia/geoserver/thanhhoa/wms/',
    datastore: 'thanhhoa',
  },
  // production: {
  //   api: 'https://api.tracuuquyhoachsonla.vn/',
  //   apiCustom: 'https://paht-api.tracuuquyhoachsonla.vn',
  //   domainUserSide: 'https://tracuuquyhoachsonla.vn',
  //   domainAdminSide: 'https://admin.tracuuquyhoachsonla.vn',
  //   domainName: 'tracuuquyhoachsonla.vn',
  //   doMainNameGeoServer: 'https://geo.tracuuquyhoachsonla.vn/geoserver/wfs',
  //   doMainNameGeoServerWMS: 'https://geo.tracuuquyhoachsonla.vn/geoserver/sonla/wms/',
  //   datastore: 'sonladev_v2',
  // },
};

module.exports = apiEnvironment[env];
